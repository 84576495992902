import { Button } from '../../form';

import classes from './Support.module.css';

const Support = () => {
    return (
        <div className={classes.root}>
            <h2 className={classes.title}>Vragen of hulp nodig?</h2>
            <div>
                <p>
                    Onze specialisten hebben veel ervaring met het samenstellen van kerstpakketten. Wij stellen uw wensen altijd centraal en gaan geen uitdaging uit de weg.

                    Wij stellen graag een mooi pakket voor u samen.
                </p>
                <Button variant="transparent" to="/contact">
                    {
                        <span>Bel met een kerstpakkettenspecialist</span>
                    }
                </Button>
            </div>
        </div>
    );
}

export default Support;