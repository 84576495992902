import React, { Suspense } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useState } from "react";

import { ShoppingbagIcon } from "../icons";
import { addCart } from '../../redux/application/actions';

import classes from './Minicart.module.css';

const CartItems = React.lazy(() => import('./CartItems/CartItems'));

const Minicart = () => {
    const dispatch = useDispatch();
    const { cart } = useSelector(state => state.app);
    const [open, setOpen] = useState();

    let cartCount = Object.keys(cart).length;

    const OpenMinicart = () => {
        setOpen(true);
    }

    const DeleteItem = (productId) => {
        let cartArray = { ...cart };

        if (productId in cart) {
            delete cartArray[productId]
        }

        dispatch(addCart(cartArray));
    }

    return (
        <>
            <div className={classes.root} onClick={() => OpenMinicart()}>
                <ShoppingbagIcon size="28" className={classes.minicart_icon} />
                {cartCount > 0 && <div className={classes.count}><span>{cartCount}</span></div>}
            </div>

            {cartCount > 0 &&
                <Suspense fallback={<></>}>
                    <CartItems cart={cart} open={open} setOpen={setOpen} onDelete={DeleteItem} />
                </Suspense>
            }
        </>
    );
}

export default Minicart;