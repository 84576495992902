
import { useEffect, useState } from 'react';

import Kerstpakketten from '../lib/api/Kerstpakketten';

const useLocation = (key, prefilled_location=null) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [location, setLocation] = useState(prefilled_location);

    useEffect(() => {

        const fetchData = async () => {

            setLoading(true);

            try {
                const { data } = await Kerstpakketten.getLocation(key);
                setLocation(data[0]?.attributes || null);
            } catch (error) {
                setError(error);
            }
        
            setLoading(false);
        
    
        }

        fetchData();


    }, [ key ]);

    return {
        location: location,
        error: error,
        loading: loading,
    };
}

export default useLocation;