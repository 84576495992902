import { Link } from 'react-router-dom';
import { Col, Row, Container } from '../../components/grid';
import Collapsable from '../../components/collapsable/Collapsable';

import useFooter from '../../hooks/useFooter';
import useMediaQuery from '../../hooks/useMediaQuery';

import classes from './Footer.module.css';

const Footer = () => {

    const { footer } = useFooter();
    const { match } = useMediaQuery('(min-width: 768px)');

    return (
        <footer className={classes.root}>
            <div className={classes.nav}>
                {
                    footer &&
                    <Container xl margins g-xs={2}>
                        <Row>
                            <Col md={3} className={classes.links}>

                                <Collapsable
                                    disabled={match}
                                    collapsed={true}
                                    renderTitle={() => <h4>Service</h4>}
                                >
                                    <ul>
                                        {
                                            footer.link_block_1.map((link, index) => {
                                                return <li key={index}><Link to={link.path}>{link.name}</Link></li>;
                                            })
                                        }
                                    </ul>
                                </Collapsable>

                            </Col>
                            <Col md={3} className={classes.links}>

                                <Collapsable
                                    disabled={match}
                                    collapsed={true}
                                    renderTitle={() => <h4>Collectie</h4>}
                                >
                                    <ul>
                                        {
                                            footer.link_block_2.map((link, index) => {
                                                return <li key={index}><Link to={link.path}>{link.name}</Link></li>;
                                            })
                                        }
                                    </ul>
                                </Collapsable>

                            </Col>
                            <Col md={3} className={classes.links}>

                                <Collapsable
                                    disabled={match}
                                    collapsed={true}
                                    renderTitle={() => <h4>Top kerstthema's</h4>}
                                >
                                    <ul>
                                        {
                                            footer.link_block_3.map((link, index) => {
                                                return <li key={index}><Link to={link.path}>{link.name}</Link></li>;
                                            })
                                        }
                                    </ul>
                                </Collapsable>

                            </Col>
                            <Col md={3}>
                                <h4>Brochure</h4>
                                <div className={classes.brochure}>
                                    <a href="https://folders.hanos.nl/kerstbrochure-2023" target="_blank" rel="noreferrer">
                                        <img loading="lazy" src="/images/footer_brochure_2023.png" width="200" height="160" alt="Brochure" className="img-responsive" />
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
            <div className={classes.terms}>
                <Container xl margins g-xs={2}>
                    <Row>
                        <Col>
                            <div className={classes.copyright}>&copy; HANOS {new Date().getFullYear()}</div>
                            <div><a href="/cookies">Cookies</a><a href="/privacy-statement">Privacy Statement</a><a href="/disclaimer">Disclaimer</a><a href="/algemene-voorwaarden">Algemene voorwaarden</a></div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    );
}

export default Footer;