import React, { useState, useReducer, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { Col, Row } from '../../../../components/grid';

import {
    Input,
    Button,
    Form,
    Textarea
} from '../../../../components/form';

import Kerstpakketten from '../../../../lib/api/Kerstpakketten';
import Config from '../../../../lib/config/Config';
import classes from './Appointment.module.css';

const initialState = {
    success: false,
    error: null,
    sending: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'form/request':
            return {
                ...state,
                success: false,
                error: null,
                sending: true
            };
        case 'form/success':
            return {
                ...state,
                success: true,
                error: null,
                sending: false
            };
        case 'form/error':
            return {
                ...state,
                success: false,
                error: action.payload,
                sending: false
            };
        default:
            throw new Error();
    }
}

const Appointment = ({ location_id }) => {
    const [inputs, setInput] = useState({ name: '', company: '', loyaltynumber: '', email: '', phone: '', location: location_id, message: '' });
    const [state, dispatch] = useReducer(reducer, initialState);
    const recaptchaRef = useRef(null);

    const onChange = ({ target: { name, value } }) => {
        setInput({
            ...inputs,
            [name]: value
        });
    }

    const onSubmit = async (valid, resetForm) => {

        if (valid) {

            const token = await recaptchaRef.current.executeAsync();

            try {
                dispatch({ type: 'form/request' });

                await Kerstpakketten.sendAppoinment({
                    ...inputs,
                    recaptcha: token || ''
                });

                dispatch({ type: 'form/success' });
                setInput({ name: '', company: '', loyaltynumber: '', email: '', phone: '', message: '' });
                resetForm();

            } catch (error) {
                dispatch({ type: 'form/error', payload: error });
            }

            recaptchaRef.current.reset();
        }
    }

    return (
        <Form onSubmit={onSubmit} id="appointment-form" className={classes.form}>
            <Row>
                {
                    state.error && <Col xs={12} ><div className={classes.error}>Formulier kon niet worden verstuurd. Probeer het later nogmaals.</div></Col>
                }
                {
                    state.success && <Col xs={12} ><div className={classes.success}>Formulier succesvol verstuurd!</div></Col>
                }
                <Col xs={12}><span className={classes.required}>* Verplicht</span></Col>

                <Col xs={12}>
                    <Input name="name" label="Naam" placeholder="Naam" onChange={onChange} value={inputs.name} required />
                </Col>
                <Col xs={12}>
                    <Input name="company" label="Bedrijf" placeholder="Bedrijf" onChange={onChange} value={inputs.company} />
                </Col>
                <Col xs={12}>
                    <Input name="loyaltynumber" label="Klantnummer" placeholder="Klantnummer" onChange={onChange} value={inputs.loyaltynumber} />
                </Col>
                <Col xs={12}>
                    <Input name="email" label="E-mailadres" placeholder="voorbeeld@email.com" onChange={onChange} value={inputs.email} required />
                </Col>
                <Col xs={12}>
                    <Input name="phone" label="Telefoonnummer" placeholder="Telefoonnummer" onChange={onChange} value={inputs.phone} required />
                </Col>
                <Col xs={12}>
                    <Textarea name="message" label="Opmerkingen" placeholder="Type hier uw speciale wensen en/of opmerkingen" onChange={onChange} value={inputs.message} />
                </Col>
                <Col xs={12}>
                    <Button variant="secondary" type="submit" loading={state.sending} id="appointment-submit" style={{ float: 'right' }}>
                        <span>Versturen</span>
                    </Button>
                </Col>
            </Row>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={Config.get('captcha').site_key}
            />
        </Form>
    );
}

export default Appointment;